import { styled } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

export const CircularButtonProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
}))
